<template>
  <div>
    <v-alert
      v-model="alert.isActive"
      border="left"
      close-text="Close Alert"
      dark
      dismissible
      :type="alert.type"
    >
      {{ alert.text }}
    </v-alert>
    <v-data-table
      item-key="id"
      :item-class="rowClass"
      :headers="headers"
      :items="orders"
      class="elevation-1"
      :loading="isLoading"
      loading-text="Загрузка... Пожалуйста, подождите"
      :sort-by="['deliveryTime']"
      :sort-desc="[true]"
      multi-sort
      mobile-breakpoint="1100"
      @dblclick:row="dblclickRow"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Заказы</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Добавить </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">
                  {{ formTitle }}
                  <span v-if="+editedItem.id"> № {{ editedItem.id }}</span>
                </span>
              </v-card-title>

              <v-card-text>
                <v-form v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="4" v-if="+editedItem.id">
                        <v-select
                          v-model="editedItem.status"
                          :items="statusArray"
                          append-icon="mdi-plus"
                          item-text="name"
                          item-value="id"
                          label="Статус"
                          single-line
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-select
                          v-model="editedItem.deliveryType"
                          :items="deliveryTypeArray"
                          append-icon="mdi-plus"
                          item-text="name"
                          item-value="id"
                          label="Получение"
                          single-line
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-dialog
                          ref="dialog"
                          v-model="modalTime"
                          :return-value.sync="editedItem.tempTime"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.tempTime"
                              label="Время готовности заказа"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="modalTime"
                            v-model="editedItem.tempTime"
                            full-width
                            format="24hr"
                            :min="startDeliveryTime"
                            max="22:00"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="red darken-1" @click="modalTime = false">
                              Закрыть
                            </v-btn>
                            <v-btn
                              text
                              color="blue darken-1"
                              @click="$refs.dialog.save(editedItem.tempTime)"
                            >
                              Сохранить
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-card>
                          <v-card-title class="grey lighten-3">
                            <v-container>
                              <v-row border="top">
                                <v-col cols="12" sm="8"> Позиции<sup>*</sup> </v-col>
                                <v-col cols="12" sm="4">
                                  <v-alert shaped outlined type="success">
                                    Цена: {{ editedItem.price }}₽
                                  </v-alert>
                                </v-col>
                              </v-row>
                              <v-divider></v-divider>
                              <v-row v-for="(meal, ind) in editedItem.positions" :key="ind">
                                <v-col cols="12" sm="6" class="text-body-1">
                                  {{ getActiveMealList.find((el) => el.id === meal.mealId).name }}
                                  ({{
                                    getActiveMealList.find((el) => el.id === meal.mealId).price
                                  }}₽)</v-col
                                >
                                <v-col cols="12" sm="4" class="text-subtitle-2">
                                  <div v-for="(addit, index) in meal.additions" :key="index">
                                    {{
                                      getActiveAdditionList.find((el) => el.id === addit.Id).name
                                    }}
                                    ({{
                                      getActiveAdditionList.find((el) => el.id === addit.Id).price
                                    }}₽) - {{ addit.count }} шт.
                                  </div>
                                </v-col>
                                <v-col cols="6" sm="1" class="text-body-1 text-center">{{
                                  meal.count
                                }}</v-col>
                                <v-col cols="6" sm="1" class="text-body-1 text-center">
                                  <v-icon @click="deleteMealFromBasket(ind)"> mdi-delete </v-icon>
                                </v-col>
                                <v-col cols="12" class="d-flex d-sm-none">
                                  <v-divider></v-divider>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-title>
                          <v-row class="pa-4" justify="space-between">
                            <v-col cols="12" sm="4">
                              <v-treeview
                                :active.sync="selectMeal.active"
                                :items="showMealListForAddDialog"
                                :open.sync="selectMeal.open"
                                activatable
                                color="warning"
                                open-on-click
                                transition
                              >
                                <template v-slot:prepend="{ item }">
                                  <v-icon v-if="!item.children"> mdi-food </v-icon>
                                </template>
                              </v-treeview>
                              <v-divider class="d-flex d-sm-none"></v-divider>
                            </v-col>

                            <v-divider vertical class="d-none d-sm-flex"></v-divider>

                            <v-col class="d-flex text-center">
                              <v-scroll-y-transition mode="out-in">
                                <div
                                  v-if="!selected"
                                  class="text-h6 grey--text text--lighten-1 font-weight-light"
                                  style="align-self: center"
                                >
                                  Выберите блюдо
                                </div>
                                <v-card v-else :key="selected.id" class="mx-auto" flat>
                                  <v-card-text>
                                    <h3 class="text-h5 mb-2">
                                      {{ selected.name }}
                                    </h3>
                                    <div class="mb-2">
                                      {{ selected.desc }}
                                    </div>
                                    <div class="success--text subheading font-weight-bold">
                                      {{ selectedMealCost }}₽
                                      <v-btn
                                        class="mx-2"
                                        fab
                                        small
                                        :disabled="!selectedMealCost"
                                        color="success"
                                        @click="addToBasket()"
                                      >
                                        <v-icon dark> mdi-basket-outline </v-icon>
                                      </v-btn>
                                    </div>
                                    <div>
                                      {{ selected.price }}₽
                                      <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        small
                                        color="primary"
                                        @click="
                                          changeMealCount(selectMeal.tempMealToBasket.count - 1)
                                        "
                                      >
                                        <v-icon dark> mdi-minus </v-icon>
                                      </v-btn>
                                      {{ selectMeal.tempMealToBasket.count }}
                                      <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        small
                                        color="primary"
                                        @click="
                                          changeMealCount(selectMeal.tempMealToBasket.count + 1)
                                        "
                                      >
                                        <v-icon dark> mdi-plus </v-icon>
                                      </v-btn>
                                    </div>
                                  </v-card-text>
                                  <v-divider></v-divider>
                                  <v-row
                                    class="text-left"
                                    tag="v-card-text"
                                    v-for="(item, ind) in selected.arAdditions"
                                    :key="ind"
                                  >
                                    <v-col class="text-right" cols="5">
                                      {{ item.name }} ({{ item.price }}₽)
                                    </v-col>
                                    <v-col cols="7">
                                      <v-btn
                                        fab
                                        dark
                                        x-small
                                        color="accent"
                                        @click="
                                          changeAdditionCount(
                                            ind,
                                            selectMeal.tempMealToBasket.arAdditions[ind].count - 1
                                          )
                                        "
                                      >
                                        <v-icon dark> mdi-minus </v-icon>
                                      </v-btn>
                                      {{ selectMeal.tempMealToBasket.arAdditions[ind].count }}
                                      <v-btn
                                        fab
                                        dark
                                        x-small
                                        color="accent"
                                        @click="
                                          changeAdditionCount(
                                            ind,
                                            selectMeal.tempMealToBasket.arAdditions[ind].count + 1
                                          )
                                        "
                                      >
                                        <v-icon dark> mdi-plus </v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-card>
                              </v-scroll-y-transition>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="editedItem.clientName"
                          label="ФИО клиента"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="editedItem.clientPhone"
                          label="Телефон"
                          :rules="rules.phone"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="editedItem.address" label="Адрес"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.comment"
                          label="Комментарий"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="close"> Закрыть </v-btn>
                <v-btn color="blue darken-1" text @click="save" :disabled="!valid">
                  Сохранить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogArchive" max-width="500px">
            <v-card>
              <v-card-title>Заказ № {{ editedItem.id }}</v-card-title>
              <v-card-text> Скрыть заказ? </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeArchive"> Закрыть </v-btn>
                <v-btn color="blue darken-1" text @click="archiveItemConfirm"> Скрыть заказ </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.positions="{ item }">
        <v-card elevation="5" outlined v-for="(order, ind) in item.positions" :key="ind">
          <v-card-title>
            {{ getActiveMealList.find((meal) => meal.id === order.mealId).name }}
            <v-chip class="ma-2" color="green" text-color="white" label>
              {{ order.count + ` шт.` }}
            </v-chip>
          </v-card-title>
          <v-list>
            <v-list-item-group>
              <template v-for="(addit, index) in order.additions">
                <v-list-item
                  v-if="getActiveAdditionList.find((a) => a.id === addit.Id)"
                  :key="index"
                >
                  <v-list-item-icon>
                    <v-chip class="ma-2" color="orange" text-color="white" label>
                      {{ addit.count + ` шт.` }}
                    </v-chip>
                  </v-list-item-icon>
                  <v-list-item-content class="text-body-2">
                    {{ getActiveAdditionList.find((a) => a.id === addit.Id).name }}
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </template>
      <template v-slot:item.status="{ item }">
        {{ statusArray.find((i) => i.id === item.status).name }}
        <v-btn v-if="nextAutochangeStatus(item.status)" outlined @click="changeStatus(item)">
          <v-icon class="mr-md-2">mdi-arrow-right</v-icon>
          <span>
            {{ statusArray.find((i) => i.id === nextAutochangeStatus(item.status)).name }}
          </span>
        </v-btn>
      </template>
      <template v-slot:item.createDate="{ item }">
        {{ item.createDate ? getDateTimeStr(item.createDate) : "" }}
      </template>
      <template v-slot:item.deliveryTime="{ item }">
        {{ item.deliveryTime ? getDateTimeStr(item.deliveryTime) : "" }}
      </template>
      <template v-slot:item.deliveryType="{ item }">
        {{ deliveryTypeArray.find((i) => i.id === item.deliveryType).name }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="canChangeOrder(item)" class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon v-if="canArchiveOrder(item)" @click="archiveItem(item)"> mdi-package-down </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="loadOrders"> Reset </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Orders",
  data: () => ({
    isLoading: true,
    headers: [
      { text: "№", value: "id" },
      { text: "Дата/Время созд.", value: "createDate" },
      { text: "Статус", value: "status" },
      { text: "Получение", value: "deliveryType" },
      { text: "Время", value: "deliveryTime" },
      { text: "Комментарий", value: "comment" },
      { text: "Состав", value: "positions" },
      { text: "Клиент", value: "clientName" },
      { text: "Телефон", value: "clientPhone" },
      { text: "Адрес", value: "address" },
      { text: "Цена, р.", value: "price" },
      { text: "", value: "actions", sortable: false },
    ],
    orders: [],
    editedIndex: -1,
    dialog: false,
    dialogArchive: false,
    editedItem: {
      id: 0,
      createDate: 0,
      status: 1,
      positions: [],
      deliveryType: 3,
      tempTime: new Date(new Date().getTime() + 20 * 60000).toLocaleTimeString("ru-RU", {
        hour: "numeric",
        minute: "numeric",
      }),
      clientName: "",
      clientPhone: "",
      address: "",
      comment: "",
      price: 0,
    },
    defaultItem: {
      id: 0,
      createDate: 0,
      status: 1,
      positions: [],
      deliveryType: 3,
      tempTime: new Date(new Date().getTime() + 20 * 60000).toLocaleTimeString("ru-RU", {
        hour: "numeric",
        minute: "numeric",
      }),
      clientName: "",
      clientPhone: "",
      address: "",
      comment: "",
      price: 0,
    },
    statusArray: [
      {
        id: 0,
        name: "Без статуса",
        archive: false,
        class: "blue-grey lighten-4",
        changeable: true,
      },
      {
        id: 1,
        name: "Новый",
        archive: false,
        class: "light-blue",
        changeable: true,
      },
      {
        id: 2,
        name: "Подтверждён",
        archive: false,
        class: "amber",
        changeable: true,
      },
      {
        id: 3,
        name: "Оплачен",
        archive: false,
        class: "orange",
        changeable: false,
      },
      {
        id: 4,
        name: "В работе",
        archive: false,
        class: "yellow",
        changeable: false,
      },
      {
        id: 5,
        name: "В доставке",
        archive: false,
        class: "lime",
        changeable: false,
      },
      {
        id: 6,
        name: "Отменён",
        archive: true,
        changeable: false,
      },
      {
        id: 7,
        name: "Выполнен",
        archive: true,
        changeable: false,
      },
    ],
    statusIdsAutochange: [1, 2, 3, 4, 5, 7],
    statusIdsArchived: [6, 7],
    deliveryTypeArray: [
      {
        id: 1,
        name: "Доставка",
        required: ["clientName", "clientPhone", "address"],
      },
      {
        id: 2,
        name: "Самовывоз",
        required: [],
      },
      {
        id: 3,
        name: "На месте",
        required: [],
      },
    ],
    modalTime: false,
    time: 0,
    selectMeal: {
      open: [],
      active: [],
      tempMealToBasket: {
        id: 0,
        count: 0,
        arAdditions: [],
      },
    },
    alert: {
      isActive: false,
      text: "",
      type: "error",
    },
    valid: true,
    rules: {
      phone: [
        (v) => {
          const pattern = /^((8|\+7)[- ]?)?(\(?\d{3,4}\)?[- ]?)?[\d- ]{5,10}$/;
          return pattern.test(v) || "Номер телефона в неверном формате.";
        },
      ],
    },
  }),
  computed: {
    ...mapState(["arOrders", "alertText"]),
    ...mapGetters(["getActiveAdditionList", "getActiveMealList", "getActiveSectionList"]),
    formTitle() {
      return this.editedIndex === -1 ? "Создать заказ" : "Редактировать заказ";
    },
    startDeliveryTime() {
      return new Date().toLocaleTimeString("ru-RU", {
        hour: "numeric",
        minute: "numeric",
      });
    },
    showMealListForAddDialog() {
      let arResult = [];
      if (this.getActiveSectionList) {
        arResult = this.getActiveSectionList
          .map((el) => {
            const children = this.getActiveMealList.filter((meal) => meal.sectionId === el.id);
            if (children.length) {
              const section = {
                name: el.name,
                id: el.id,
                children,
              };
              return section;
            }
            return null;
          })
          .filter((el) => el != null);
      }
      return arResult;
    },
    selected() {
      if (!this.selectMeal.active.length) return undefined;

      const id = this.selectMeal.active[0];

      let activeEl = { ...this.getActiveMealList.find((meal) => meal.id === id) };

      if (activeEl) {
        activeEl.arAdditions = activeEl.arAdditions
          .map((addit) => {
            const additionInfo = this.getActiveAdditionList.find((addEl) => {
              const res = addEl.id === addit;
              return res;
            });
            return additionInfo;
          })
          .filter((element) => element != null);
      } else {
        activeEl = undefined;
      }

      return activeEl;
    },
    selectedMealCost() {
      let cost = 0;
      const mealId = this.selectMeal.tempMealToBasket.id;
      if (this.selectMeal.tempMealToBasket.count) {
        cost = +this.getActiveMealList.find((el) => el.id === mealId).price;
        this.selectMeal.tempMealToBasket.arAdditions.forEach((elem) => {
          if (elem.count) {
            cost += +this.getActiveAdditionList.find((el) => el.id === elem.id).price * elem.count;
          }
        });
        cost *= +this.selectMeal.tempMealToBasket.count;
      }

      return cost;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.alert.isActive = false;
      }
      return val || this.close();
    },
    dialogArchive(val) {
      if (val) {
        this.alert.isActive = false;
      }
      return val || this.closeArchive();
    },
    selected() {
      if (this.selected) {
        this.selectMeal.tempMealToBasket.id = this.selected.id;
        this.selectMeal.tempMealToBasket.count = 0;
        this.selectMeal.tempMealToBasket.arAdditions = this.selected.arAdditions.map((el) => {
          const obj = {
            id: el.id,
            count: 0,
          };
          return obj;
        });
      }
    },
  },
  methods: {
    ...mapActions([
      "getOrderList",
      "addOrder",
      "editOrder",
      "archiveOrder",
      "getSectionList",
      "getAdditionList",
      "getMealList",
    ]),
    showOrders() {
      const arr = Object.keys(this.arOrders).map((value) => {
        const obj = this.arOrders[value];
        obj.id = value;
        if (!obj.deliveryTime) {
          obj.deliveryTime = 0;
          obj.tempTime = "";
        } else {
          obj.tempTime = new Date(obj.deliveryTime).toLocaleTimeString("ru-RU", {
            hour: "numeric",
            minute: "numeric",
          });
        }

        return obj;
      });
      this.orders = arr;
    },
    async loadOrders() {
      this.isLoading = true;
      try {
        await this.getOrderList();
      } catch (e) {
        this.alert.text = this.alertText.errLoadData;
        this.alert.type = "error";
        this.alert.isActive = true;
      }
      this.showOrders();
      this.isLoading = false;
    },
    changeEditedItem(item) {
      this.editedIndex = this.orders.indexOf(item);
      this.editedItem = { ...item };
      if (!this.editedItem.positions) {
        this.editedItem.positions = [];
      }
    },
    editItem(item) {
      this.changeEditedItem(item);
      this.dialog = true;
    },
    dblclickRow(value, value2) {
      if (this.canChangeOrder(value2.item)) {
        this.editItem(value2.item);
      }
    },
    archiveItem(item) {
      this.editedIndex = this.orders.indexOf(item);
      this.editedItem = { ...item };
      this.dialogArchive = true;
    },
    async archiveItemConfirm() {
      try {
        await this.archiveOrder(this.editedItem);
        this.showOrders();
      } catch (error) {
        this.alert.text = this.alertText.errArchiveData;
        this.alert.type = "error";
        this.alert.isActive = true;
      }

      this.closeArchive();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    closeArchive() {
      this.dialogArchive = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    async save() {
      const oldDeliveryTime = new Date(this.editedItem.deliveryTime);
      const oldDeliveryHour = oldDeliveryTime.getHours();
      const oldDeliveryMinute = oldDeliveryTime.getMinutes();
      const [newDeliveryHour, newDeliveryMinute] = this.editedItem.tempTime.split(":");

      if (
        !this.editedItem.deliveryTime ||
        +newDeliveryHour !== +oldDeliveryHour ||
        +newDeliveryMinute !== +oldDeliveryMinute
      ) {
        this.editedItem.deliveryTime = new Date().setHours(newDeliveryHour, newDeliveryMinute);
      }

      try {
        if (this.editedIndex > -1) {
          await this.editOrder(this.editedItem);
        } else {
          await this.addOrder(this.editedItem);
        }
        this.showOrders();
      } catch (error) {
        this.alert.text = this.alertText.errAddData;
        this.alert.type = "error";
        this.alert.isActive = true;
      }

      this.close();
    },
    async changeStatus(item) {
      this.changeEditedItem(item);
      this.editedItem.status = this.nextAutochangeStatus(this.editedItem.status);
      await this.save();
    },
    rowClass(item) {
      let className = "";
      const statusName = this.statusArray.find((i) => i.id === item.status);
      if (statusName) {
        className = statusName.class;
      }
      if (item.deliveryTime <= new Date().getTime() && !(item.status === 6 || item.status === 7)) {
        // className = "error";
      }
      return className;
    },
    getDateTimeStr(date) {
      let result = "";
      if (date) {
        let thisDayStart = new Date();
        thisDayStart.setHours(0, 0, 0, 0);
        thisDayStart = thisDayStart.getTime();
        let thisDayEnd = new Date();
        thisDayEnd.setHours(0, 0, 0, 0);
        thisDayEnd.setDate(thisDayEnd.getDate() + 1);
        thisDayEnd = thisDayEnd.getTime();
        if (date >= thisDayStart && date < thisDayEnd) {
          result = new Date(date).toLocaleTimeString("ru-RU", {
            hour: "numeric",
            minute: "numeric",
          });
        } else {
          result = new Date(date).toLocaleDateString("ru-RU", {
            hour: "numeric",
            minute: "numeric",
          });
        }
      }

      return result;
    },
    changeMealCount(count) {
      let newCount = count;
      if (newCount < 0) {
        newCount = 0;
      }
      this.selectMeal.tempMealToBasket.count = newCount;
    },
    changeAdditionCount(ind, count) {
      const newCount = count < 0 ? 0 : count;

      this.selectMeal.tempMealToBasket.arAdditions[ind].count = newCount;

      if (count > 0 && this.selectMeal.tempMealToBasket.count === 0) {
        this.changeMealCount(1);
      }
    },
    addToBasket() {
      const arResult = {
        mealId: this.selectMeal.tempMealToBasket.id,
        count: this.selectMeal.tempMealToBasket.count,
        additions: [],
      };
      this.selectMeal.tempMealToBasket.arAdditions.forEach((el, key) => {
        if (el.count) {
          arResult.additions.push({
            Id: el.id,
            count: el.count,
          });
          this.selectMeal.tempMealToBasket.arAdditions[key].count = 0;
        }
      });
      this.editedItem.positions.push(arResult);
      this.selectMeal.tempMealToBasket.count = 0;
      this.editedItem.price = this.getSelectedBasketCost();
    },
    deleteMealFromBasket(index) {
      this.editedItem.positions.splice(index, 1);
      this.editedItem.price = this.getSelectedBasketCost();
    },
    getSelectedBasketCost() {
      let cost = 0;
      this.editedItem.positions.forEach((order) => {
        let orderCost = +this.getActiveMealList.find((meal) => meal.id === order.mealId).price;
        order.additions.forEach((addit) => {
          orderCost +=
            +this.getActiveAdditionList.find((allAddit) => allAddit.id === addit.Id).price *
            addit.count;
        });
        orderCost *= +order.count;
        cost += +orderCost;
      });
      return cost;
    },
    nextAutochangeStatus(id) {
      let result;
      const statusId = this.statusIdsAutochange.findIndex((el) => el === id);

      if (statusId > -1 && this.statusIdsAutochange[statusId + 1]) {
        result = this.statusIdsAutochange[statusId + 1];
      }

      return result;
    },
    canChangeOrder(elem) {
      const result = this.statusArray.find((st) => st.id === elem.status).changeable;
      return result;
    },
    canArchiveOrder(elem) {
      let result = false;
      if (this.statusIdsArchived.find((el) => el === elem.status)) {
        result = true;
      }

      return result;
    },
  },
  async mounted() {
    if (
      Object.keys(this.getActiveSectionList).length === 0 ||
      Object.keys(this.getActiveAdditionList).length === 0 ||
      Object.keys(this.getActiveMealList).length === 0
    ) {
      try {
        await this.getSectionList();
        await this.getAdditionList();
        await this.getMealList();
      } catch (error) {
        this.alert.text = this.alertText.errLoadData;
        this.alert.type = "error";
        this.alert.isActive = true;
      }
    }
    try {
      await this.loadOrders();
    } catch (error) {
      this.alert.text = this.alertText.errLoadData;
      this.alert.type = "error";
      this.alert.isActive = true;
    }
  },
};
</script>
